.manage-patient-list {
  padding: 20px;

  .header-row {
    margin-bottom: 24px;

    .create-button {
      float: right;
      padding: 8px 24px;
      height: auto;
      font-weight: 500;
      border-radius: 6px;
      
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .patient-list-card {
    margin-bottom: 16px;

    .text-label {
      color: #000;
      font-size: 12px;
    }

    .list-name {
      color: #6750A4;
      font-weight: bold;
      cursor: pointer;
      font-size: 14px;
    }

    .bold-text {
      font-weight: bold;
      font-size: 14px;
    }

    .criteria-content {
      display: flex;
      align-items: flex-start;
      
      .criteria-text {
        flex: 1;
        transition: max-height 0.3s ease-out;
        overflow: hidden;
        
        &.collapsed {
          max-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        &.expanded {
          max-height: 500px;
          white-space: normal;
        }
      }

      .toggle-button {
        padding: 0 8px;
        margin-left: 8px;
      }
    }
  }

  .empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
} 