.assessment-container-loader {
  width: 100%;
  // height: calc(100vh);
  margin: 150px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment-container-error {
  width: 100%;
  // height: calc(100vh);
  margin: 150px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-msg {
    font-size: 14px;
    color: #777;
  }
}

.assessment-container {
  height: 100%;
  .title {
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 16px;
    color: #760fb2;
  }
  .filters-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
