.redirect-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 48px;
  .banner-text {
    color: gray;
    font-size: 18px;
  }
  .button {
    width: 100px;
    padding: 8px;
    height: auto;
    margin: auto;
  }
}
