.side-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent background
  z-index: 1000;
  display: flex;
  justify-content: flex-start; // Adjust alignment based on drawer position
  align-items: stretch;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .side-drawer-content {
    background: #fff;
    width: 70%; // Occupies 70% of the screen width
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    // transform: translateX(100%);
    // transition: transform 0.3s ease;

    &.left {
      transform: translateX(-70%);
    }

    &.open.left {
      transform: translateX(0);
    }

    &.right {
      transform: translateX(43%);
    }

    &.open.right {
      transform: translateX(0);
    }

    .header-container {
      padding: 16px;
      border-bottom: 1px solid #f1c2ff;
      width: 100%;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #760fb2 !important;
        margin: 0;
        text-align: left;
      }

      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #333;

        &:hover {
          color: #000;
        }
      }
    }
    .side-drawer-main-content {
      padding: 16px;
      overflow-y: auto;
      max-height: calc(
        100% - 90px
      ); // Adjust the height to ensure it fits within the bottom sheet
      height: 100%;
      overflow-x: hidden;
    }
  }
}
