.bottom-sheet {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  .bottom-sheet-content {
    width: 100%;
    height: 100%;
    background: #fff;
    // border-radius: 20px 20px 0 0;
    //   padding: 20px;
    position: relative;
    overflow-y: auto;
    .header-container {
      padding: 16px;
      border-bottom: 1px solid #f1c2ff;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #760fb2;
      }

      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #333;

        &:hover {
          color: #000;
        }
      }
    }
    .bottom-sheet-main-content {
      padding: 16px;
      overflow-y: auto;
      max-height: calc(
        100% - 90px
      ); // Adjust the height to ensure it fits within the bottom sheet
    }
  }
}
