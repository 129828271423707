.patient-table {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(118, 15, 178, 0.08);
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  min-width: 1400px; /* Adjusted to accommodate wider columns */
  position: relative;

  .table-header {
    display: grid;
    grid-template-columns: 100px 200px 120px 140px 140px 140px 120px 140px 140px 140px 160px 160px 100px;
    padding: 16px 24px;
    background: linear-gradient(to right, #f7f2fc, #f0e7f7);
    border-bottom: 2px solid #e9dcf5;
    position: sticky;
    top: 0;
    z-index: 2;
    background-attachment: fixed;

    .header-cell {
      font-weight: 600;
      font-size: 14px;
      color: #4a4a4a;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      white-space: nowrap;
      padding: 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .table-body {
    position: relative;
    z-index: 1;

    .table-row {
     
      display: grid;
      grid-template-columns: 60px 200px 120px 140px 140px 140px 120px 140px 140px 140px 160px 160px 100px;
      padding: 16px 24px;
      border-bottom: 1px solid #f0f0f0;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      background-color: white;
      position: relative;
      

      &:hover {
        background-color: #faf6fe;
        transform: translateY(-1px);
        box-shadow: 0 2px 8px rgba(118, 15, 178, 0.05);
        z-index: 1;
      }

      .cell {
        font-size: 14px;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        line-height: 1.4;
        padding: 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        min-height: 40px;
        background: inherit;
        position: relative;

        &.patient-info {
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          white-space: normal;

          .name {
            color: #760FB2;
            font-weight: 600;
            font-size: 15px;
          }

          .details {
            font-size: 13px;
            color: #666;
          }
        }

        &.plan-info {
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          white-space: normal;

          .expiry {
            font-size: 12px;
            color: #888;
          }
        }

        .last-active-info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          
          .timestamp {
            font-size: 12px;
            color: #666;
          }
        }
      }

      .action-buttons {
        display: flex;
        gap: 12px;
        align-items: center;

        .action-btn {
          padding: 8px;
          border: none;
          background: #f7f2fc;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: #efe5f9;
            transform: translateY(-1px);
          }

          img {
            opacity: 0.85;
            transition: opacity 0.2s ease;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.patient-table-wrapper {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(118, 15, 178, 0.08);
  width: 100%;
  min-width: 1400px;

  .patient-info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .name {
      color: #760FB2;
      font-weight: 600;
      font-size: 15px;
    }

    .details {
      font-size: 13px;
      color: #666;
    }
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .expiry {
      font-size: 12px;
      color: #888;
    }
  }

  .last-active-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .timestamp {
      font-size: 12px;
      color: #666;
    }
  }

  .action-buttons {
    display: flex;
    gap: 12px;
    align-items: center;

    .action-btn {
      padding: 8px;
      border: none;
      background: #f7f2fc;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #efe5f9;
        transform: translateY(-1px);
      }

      img {
        opacity: 0.85;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
