.button {
  padding: 8px 10px;
  border-radius: 4px;
  width: 150px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
  border: none;
  height: 40px;
  opacity: 1; // Default opacity

  &.primary {
    background: #760fb2;
    color: #fff;

    &:hover {
      background: #6b0da8; // Example hover effect for primary
    }
  }

  &.secondary {
    background: #fff;
    color: #760fb2;

    &:hover {
      background: #f0e5f6;
    }
  }

  &.outlined {
    border: 1px solid #760fb2;
    background: #fff;
    color: #760fb2;

    &:hover {
      background: #f0e5f6;
    }
  }

  &:disabled {
    background: #eeeeee; // Uniform disabled background
    color: #777; // Uniform disabled text color
    cursor: not-allowed; // Disable pointer cursor
    opacity: 0.6; // Reduce opacity for all buttons
    border-color: #777; // Apply border color to outlined buttons
    &:hover {
      background: #eeeeee; // Disable hover effect when disabled
    }
  }

  &.full-width {
    width: 100%;
  }
}
