.assessment-goal {
  margin-top: 1rem;
  position: relative;
  // overflow-x: auto;

  // Add these new styles for table layout
  .table-container {
    position: relative;
    width: 100%;
    
    table {
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0;
      
      th, td {
        width: 250px; // Set fixed width for assessment columns
        
        &:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          background: white;
          width: 400px; // Fixed width for GF Goals column
        }
      }

      th {
        background: white;
        z-index: 3;
      }
    }

    // Add max-width to container to show only 2 columns at a time
    max-width: calc(400px + 300px * 2 + 100px); // GF Goals + 2 assessment columns
    // margin: 0 auto; // Center the table container
    overflow-x: auto;
  }

  // Add horizontal scrollbar styling
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    
    &:hover {
      background: #555;
    }
  }

  .title-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 1rem;
    padding: 1rem 0;

    .title-date {
      display: flex;
      align-items: center;
      gap: 1rem;
      position: relative;

      .title {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .date {
        color: #666;
        font-size: 14px;
      }

      .more-options-btn {
        width: 24px;
        height: 24px;
        border: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        
        &:hover {
          background-color: #f5f5f5;
          border-radius: 4px;
        }
      }
    }

    .header-buttons {
      .header-btn {
        padding: 8px;
        height: auto;
        width: auto;
      }
    }

    .assessment-actions {
      display: flex;
      gap: 0.5rem;

      button {
        padding: 0.5rem 1rem;
        height: 32px;
        width: auto;
        max-width: 150px;
      }

      .cancel-btn {
        color: #dc3545;
        border-color: #dc3545;
        
        &:hover {
          background-color: #dc354510;
        }
      }
    }
  }

  .goal-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem;

    .collapse-btn {
      width: 24px;
      height: 24px;
      border: 1px solid #ddd;
      border-radius: 4px;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        background-color: #f5f5f5;
      }
    }

    .goal-checkbox {
      margin: 0;
    }

    .goal-title {
      font-weight: 500;
    }

    .more-options-btn {
      width: 24px;
      height: 24px;
      border: none;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-left: auto; // This will push the button to the right
      
      &:hover {
        background-color: #f5f5f5;
        border-radius: 4px;
      }
    }
  }

  .goal-item {
    height: 100%;

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      
      .sub-goals-div {
        select {
          background-color: #f5f5f5;
          cursor: not-allowed;
        }
      }
    }
  }

  .sub-goals-div-goal{
    padding-left: 1rem;
    padding-right: 1rem;

    goal-metrics:first-child {
      margin-top: 3rem;
    }

    .goal-metrics {
      padding: 0.5rem 0;
      height: 40px;

      .metric {
        display: flex;
        align-items: center;
        gap: 1rem;

        .metric-details {
          min-width: 200px;

          .metric-name {
            font-weight: 500;
            margin-bottom: 0.25rem;
          }

          .metric-target {
            font-size: 0.9rem;
            color: #666;
          }
        }
      }
    }

  }

  .sub-goals-div {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 4rem;

    goal-metrics:first-child {
      margin-top: 3rem;
    }

    .goal-metrics {
      padding: 0.5rem 0;
      height: 40px;

      .metric {
        display: flex;
        align-items: center;
        gap: 1rem;

        .metric-details {
          min-width: 200px;

          .metric-name {
            font-weight: 500;
            margin-bottom: 0.25rem;
          }

          .metric-target {
            font-size: 0.9rem;
            color: #666;
          }
        }
      }
    }
  }

  // Style for the Select component within assessment columns
  .sub-goals-div {
    select {
      width: 100%;
      min-width: 150px;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: white;
      
      &:focus {
        outline: none;
        border-color: #0066cc;
        box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
      }
    }
  }
}

.custom-goal-modal {
  padding: 20px;
  
  h2 {
    margin-bottom: 20px;
  }

  .goals-list {
    margin-top: 20px;
    max-height: 400px;
    overflow-y: auto;

    .goal-item {
      padding: 10px;
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: none;
      }

 

      .goal-checkbox-label {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        cursor: pointer;

        input[type="checkbox"] {
          margin: 0;
        }
      }
    }
  }

  .modal-actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}

.completion-modal {
  padding: 20px;
  
  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .modal-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}

.preview-modal {
  // width: %;
  h2 {
    margin-bottom: 20px;
  }

  .health-progress-grid {
    display: flex;
    // grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 24px;
  }

  .modal-actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}

.health-progress {
  margin-bottom: 24px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  width: 77%;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .assessment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    h3 {
      margin: 0;
      font-size: 20px;
    }

    .date {
      color: #666;
    }
  }

  .progress-section {
    margin-top: 24px;

    h3 {
      margin: 0;
      margin-bottom: 4px;
    }

    .date-range {
      color: #666;
      font-size: 14px;
    }
  }

  .progress-circle {
    text-align: center;
    margin: 32px 0;
    position: relative;

    .circle-value {
      font-size: 48px;
      font-weight: bold;
    }

    .status {
      color: #4B4ACF;
      margin: 8px 0;
    }

    .range {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      color: #666;
    }
  }

  .score-cards {
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(2, 0.7fr);
    gap: 16px;
    margin-top: 24px;

    .score-card {
      border: 1px solid #e0e0e0;
      padding: 16px;
      text-align: center;
      border-radius: 4px;

      h2 {
        margin: 0;
        font-size: 32px;
        color: #333;
      }

      p {
        margin: 8px 0;
        color: #666;
      }

      .change {
        font-size: 14px;
        color: #4B4ACF;
      }
    }
  }
}


.progress-section {
  padding: 20px;
background: #eeeeee;
border-radius: 8px;
// box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .progress-cards {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    // overflow-x: auto;
  }

  .progress-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
  }

  .arrow {
    font-size: 24px;
    color: #666;
  }

  .gauge-container {
    text-align: center;
    margin-bottom: 15px;
  }

  .gauge {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 15px solid #5c6bc0;
    border-bottom-color: white; 
    margin: 0 auto 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .score {
      font-size: 32px;
      font-weight: bold;
      color: #333;
    }

    .status {
      font-size: 14px;
      color: #5c6bc0;
    }
  }

  .range {
    display: flex;
    justify-content: space-between;
    color: #666;
    font-size: 14px;
  }

  .details {
    text-align: center;

    .name {
      font-weight: 500;
      margin-bottom: 5px;
    }

    .date {
      color: #666;
      font-size: 14px;
    }
  }
  
}


.user-details-modal {
  padding: 1rem;

  h2 {
    color: #2D3748;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    border-bottom: 2px solid #E2E8F0;
    padding-bottom: 0.75rem;
  }

  .user-info {
    display: grid;
    gap: 1rem;

    p {
      margin: 0;
      padding: 0.75rem;
      background-color: #F7FAFC;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      
      strong {
        min-width: 100px;
        color: #4A5568;
      }
    }

    p:hover {
      background-color: #EDF2F7;
      transition: background-color 0.2s ease;
    }
  }
}

.goal-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;

  .collapse-btn {
    width: 24px;
    height: 24px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .goal-checkbox {
    margin: 0;
  }

  .goal-title {
    font-weight: 500;
  }

  .more-options-btn {
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: auto; // This will push the button to the right
    
    &:hover {
      background-color: #f5f5f5;
      border-radius: 4px;
    }
  }
}

.sub-goals-div-goal{
  padding-left: 3rem;

  goal-metrics:first-child {
    margin-top: 3rem;
  }

  .goal-metrics {
    padding: 0.5rem 0;
    height: 40px;

    .metric {
      display: flex;
      align-items: center;
      gap: 1rem;

      .metric-details {
        min-width: 200px;

        .metric-name {
          font-weight: 500;
          margin-bottom: 0.25rem;
        }

        .metric-target {
          font-size: 0.9rem;
          color: #666;
        }
      }
    }
  }

}

.sub-goals-div {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 4rem;

  goal-metrics:first-child {
    margin-top: 3rem;
  }

  .goal-metrics {
    padding: 0.5rem 0;
    height: 40px;

    .metric {
      display: flex;
      align-items: center;
      gap: 1rem;

      .metric-details {
        min-width: 200px;

        .metric-name {
          font-weight: 500;
          margin-bottom: 0.25rem;
        }

        .metric-target {
          font-size: 0.9rem;
          color: #666;
        }
      }
    }
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.skip-modal-content {
  padding: 20px;
  
  p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
  }
}

.skip-modal-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  
  button {
    min-width: 120px;
  }
}