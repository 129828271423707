.diet-reports-page-container {
  .page-title {
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 16px;
  }
  .generate-report-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    .button {
      margin-top: 24px;
      height: 32px;
    }
  }
  .reports-container {
    padding: 16px;
    padding-top: 32px;
  }
}
