.preview-columns {
  display: flex;
  gap: 24px;
  padding: 16px;
  height: 100%;

  .preview-column {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h3 {
      margin: 0 0 16px;
      font-size: 18px;
      font-weight: 500;
      color: #333;
    }
  }
} 