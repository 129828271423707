.assessment-side-drawer {
  .tabs-container {
    padding: 20px 0px;
    margin-left: -16px;
    margin-left: -16px;
    width: calc(100% + 32px);
  }
  .tabs-content {
    height: 100%;
  }
}
