.diagnostic-drawer {
  padding: 20px;
  width: 50%;
margin: auto;

  .upload-button {
    width: 40%;
    height: 48px;
    font-size: 16px;
  }

  .divider {
    text-align: center;
    margin: 20px 0;
    color: #666;
    font-size: 14px;
  }

  .search-section {
    margin-bottom: 20px;

    .search-input {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      
      input {
        padding-left: 40px;
      }
    }
  }

  .records-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);

    .record-card {
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
      position: relative;
      display: flex;
      // flex-direction: column;

      &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      &.disabled {
        opacity: 0.6;
        
        &:hover {
          background-color: inherit; // Prevent hover effects
          cursor: default;
        }
      }

      .image-placeholder {
        background-color: #f5f5f5;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 48px;
          height: 48px;
          object-fit: contain;
        }
      }

      .record-info {
        padding: 12px;

        .record-name {
          font-size: 16px;
          font-weight: 500;
          color: #333;
          margin-bottom: 4px;
        }

        .upload-date {
          font-size: 12px;
          color: #666;
        }
      }

      .error-message {
        color: #ff4d4f;
        font-size: 12px;
        margin-top: 4px;
      }

      .record-content {
        flex: 1;
        // display: flex;
        cursor: pointer;
      }

      .download-button {
        position: absolute;
        top: 8px;
        right: 8px;
        padding: 6px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        cursor: pointer;
        font-size: 12px;
        color: #666;
        transition: all 0.2s ease;
        
        &:hover {
          background: #f5f5f5;
          border-color: #d0d0d0;
          color: #333;
        }

        &:active {
          background: #e8e8e8;
          transform: translateY(1px);
        }
      }
    }
  }
}

.no-records-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
  color: #666;
  font-size: 24px;
  text-align: center;
}
