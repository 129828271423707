.category-tabs {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    .tab {
      padding: 8px 16px;
      border: 1px solid #5c6bc0;
      border-radius: 20px;
      background: none;
      color: #5c6bc0;
      cursor: pointer;
      transition: all 0.3s;

      &.active {
        background: #5c6bc0;
        color: white;
      }

      &:hover {
        background: #5c6bc0;
        color: white;
      }
    }
  }

.sticky-tabs-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white; // Or match your app's background color
  
  .category-tabs {
    // Preserve your existing category-tabs styles
  }
}