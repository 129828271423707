.goal-table {
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    
    th, td {
      width: 220px;
      
      &:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background: white;
        width: 400px;
        min-width: 400px;
      }
    }

    th {
      background: white;
      z-index: 3;
    }
  }

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }

  .goal-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem;
  }

  // ... rest of the table-specific styles ...
}

.table-container {
//   overflow-x: auto;
  position: relative;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
} 