.health-progress {
  margin-bottom: 24px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  width: 77%;

  .assessment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .progress-section {
    margin-top: 24px;
  }

  // ... rest of the health progress styles ...
} 