.container-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-wrapper {
        width: 90vw;
    }

    .diet-template-wrapper {
        justify-content: flex-start !important;
    }

    .custom-table {
        .ant-table-thead>tr>th {
            background-color: #760fb2;
            color: white;
            border-radius: 0px !important;
            font-weight: 700;
        }

        .ant-pagination {

            .ant-pagination-item,
            .ant-pagination-item-active {
                border-color: #760fb2;

                a {
                    color: #760fb2;
                }
            }

            .ant-pagination-item-active {
                font-weight: bold;
            }
        }
    }
}

.custom-modal {
    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 85vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        height: 90%;

        .view-assessment-wrapper {
            .custom-card .ant-card-head {
                padding: 0px 8px;
                font-size: 14px;
                color: #760fb2;
                font-weight: 700;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card .ant-card-body {
                padding: 12px;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-table {
                height: fit-content;
                overflow: auto;

                .ant-table-thead>tr>th {
                    color: #760fb2;
                    // color: white;
                    border-radius: 0px !important;
                    font-weight: 700;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    ::-webkit-scrollbar-thumb {
        background: #af93bf;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #760fb2;
    }
}

.custom-add-modal {
    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 85vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        height: 90%;

        .view-assessment-wrapper {
            .custom-card .ant-card-head {
                padding: 0px 8px;
                font-size: 14px;
                color: #760fb2;
                font-weight: 700;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card .ant-card-body {
                padding: 12px;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-table {
                height: fit-content;
                overflow: auto;

                .ant-table-thead>tr>th {
                    color: #760fb2;
                    // color: white;
                    border-radius: 0px !important;
                    font-weight: 700;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    ::-webkit-scrollbar-thumb {
        background: #af93bf;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #760fb2;
    }
}

.custom-copy-modal {
    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 25vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        height: 90%;

        .view-assessment-wrapper {
            .custom-card .ant-card-head {
                padding: 0px 8px;
                font-size: 14px;
                color: #760fb2;
                font-weight: 700;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card .ant-card-body {
                padding: 12px;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-table {
                height: fit-content;
                overflow: auto;

                .ant-table-thead>tr>th {
                    color: #760fb2;
                    // color: white;
                    border-radius: 0px !important;
                    font-weight: 700;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    ::-webkit-scrollbar-thumb {
        background: #af93bf;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #760fb2;
    }
}

.table-container {
    padding: 24px;
    
    .button-wrapper {
      margin-bottom: 20px;
    }
  }