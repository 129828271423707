.container-wrapper-coach {
  .diet-template-container-loader {
    width: 100%;
    // height: calc(100vh);
    margin: 150px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-table-diet {
    margin-top: 20px;
    padding: 0px !important;

    :where(.css-dev-only-do-not-override-d2lrxs) .ant-table-wrapper.custom-table-diet {
      margin-top: 20px;
    }

    .ant-table-thead>tr>th {
      background-color: #760fb2;
      color: white;
      border-radius: 0px !important;
      font-weight: 700;
    }

    .ant-pagination {

      .ant-pagination-item,
      .ant-pagination-item-active {
        border-color: #760fb2;

        a {
          color: #760fb2;
        }
      }

      .ant-pagination-item-active {
        font-weight: bold;
      }
    }

    .custom-radio .ant-radio {
      transform: scale(1.2);
      /* Make the radio button larger */
      margin-right: 8px;
      /* Add some spacing */
    }

    .custom-radio .ant-radio-inner {
      border-color: #760fb2;
      /* Change the border color */
    }

    .custom-radio .ant-radio-checked .ant-radio-inner {
      background-color: #760fb2;
      /* Change the background color when checked */
    }
  }
}