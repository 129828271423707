.completion-modal {
  padding: 20px;
  
  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .modal-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
} 