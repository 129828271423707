.create-diet-plan-container {
  position: relative;
  .title {
    text-align: center;
    margin-top: 0;
    color: #760fb2;
  }
  .create-diet-plan-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .create-diet-btn {
      width: 200px;
    }
    .view-assessment-btn {
      margin-right: 80px;
    }
  }
  .create-new-diet-plan-container {
    .back-btn {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 48px;
      width: 80px;
      .back-btn-text {
        // font-weight: 600;
        font-size: 18px;
        margin-left: 10px;
      }
      &:hover {
        background-color: #f0e5f6;
      }
    }
  }
}
