.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .popup-container {
    background: #fff;
    border-radius: 8px;
    min-width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: popup-fade-in 0.3s ease-in-out;

    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      border-bottom: 1px solid #ddd;

      .popup-title {
        margin: 0;
        font-size: 1rem;
        // color: #333;
        color: #760fb2;
      }

      .popup-close {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #999;
        transition: color 0.2s;

        &:hover {
          color: #333;
        }
      }
    }

    .popup-content {
      padding: 1rem;
      font-size: 1rem;
      color: #555;
      overflow-y: scroll;
      max-height: calc(100vh - 180px);
    }
  }

  @keyframes popup-fade-in {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}
