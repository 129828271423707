.tabs-wrapper {
  border-bottom: 1px solid #760fb2;
  padding-left: 16px;
  .tabs-container {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    .tab {
      border: none;
      background-color: white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 150px;
      flex: 1;
      padding: 10px 20px;
      color: #333;
      font-weight: bold;
      cursor: pointer;
      &.active-tab {
        color: #fff !important;
        background-color: #760fb2 !important;
      }
      &:hover {
        background-color: #f0e5f6;
        color: #760fb2;
      }
    }
  }
}
