.preview-modal {
//   width: 94%;
  
  h2 {
    margin-bottom: 20px;
  }

  .health-progress-grid {
    display: flex;
    // grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 24px;
  }

  .modal-actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}

.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  color: #666;
  font-size: 16px;
} 