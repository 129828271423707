.custom-goal-modal {
  padding: 20px;
  
  h2 {
    margin-bottom: 20px;
  }

  .goals-list {
    margin-top: 20px;
    max-height: 400px;
    overflow-y: auto;
  }

  // ... rest of the custom goal modal styles ...
} 