.patient-profile-modal-container {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .custom-card {
    background-color: #fbf3ff;
    border-radius: 16px;
    padding: 16px;
    padding-top: 48px;
    padding-bottom: 24px;
    position: relative;
    .card-title {
      position: absolute;
      top: 8px;
      left: 16px;
      font-weight: 600;
      color: #760fb2;
    }
    .field-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      .field {
        .field-label {
          font-size: 12px;
          color: #777;
        }
        .field-value {
          font-size: 16px;
          font-weight: 600;
          min-height: 32px;
        }
      }
    }
    .feature {
      display: flex;
      flex-direction: column;
      // gap: 5px;
      .feature-label {
        font-size: 12px;
        color: #777;
      }
      .feature-value {
        font-size: 18px;
        color: #333;
        font-weight: 600;
      }
      .feature-plan-name {
        max-width: 180px;
      }
    }
    .feature-service {
      background-color: #efefef;
      padding: 10px 16px;
      border-radius: 16px;
      margin-top: 24px;
      .feature-services-value {
        display: flex;
        align-items: center;
        // padding-right: 16px;
        font-size: 14px;
        gap: 8px;
      }
    }
  }
}
