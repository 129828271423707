.diet-list-container {
  .diet-filter-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .select-container {
      width: auto;
    }
    button {
      align-self: flex-end;
      margin-bottom: 16px;
    }
  }
  .diet-plans-list {
    list-style: none;
    margin: 0px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-bottom: 20px;
    li {
      margin: 0;
      padding: 0;
      .diet-plan-card {
        padding: 16px;
        background-color: transparent;
        border: 1px solid #fbf3ff;
        background-color: #fbf3ff;
        border-radius: 8px;
        text-align: left;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;

        &:hover {
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          background-color: #f0e5f6;
        }

        .diet-plan-select {
          padding-right: 16px;
          .custom-radio {
            width: 20px;
            height: 20px;
            border: 1px solid #000;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
              border-width: 2px;
              border-color: #760fb2;
            }

            .active {
              color: #760fb2;
              font-weight: 700;
              // font-size: 16px;
            }
          }
        }
        .diet-plan-details {
          .plan-name-wrapper {
            display: flex;
            align-items: flex-end;
            gap: 5px;
            .plan-name {
              margin: 0;
              padding: 0;
              margin-bottom: 10px;
              font-size: 18px;
            }
            .plan-status-badge {
              margin-bottom: 12px;
            }
          }
        }

        .diet-plan-actions {
          display: flex;
          align-items: center;
          gap: 8px;
          .view-pdf-btn {
            height: auto;
            padding: 6px;
            width: auto;
            font-size: 11px;
          }
        }
      }
    }
  }

  .view-all-btn {
    color: #760fb2;
    text-decoration: underline;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 20px 10px;
    padding-top: 0px;
    &:hover {
      font-weight: 600;
    }
  }
}
