.exercise-plan-page-container {
  .page-title {
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 32px;
  }
  .exercise-plan-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
