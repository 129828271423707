.user-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  .i-phone-img {
    width: 800px;
  }
  .user-view-iframe {
    // width: 100%;
    width: 360px;
    height: 680px;
    border: 1px solid #ccc;
  }
}
