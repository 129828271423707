.layout-container-loader {
  width: 100%;
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-container-error {
  width: 100%;
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  .error-msg {
    font-size: 14px;
    color: #777;
  }
  .flex-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.layout-container {
  .tabs-wrapper {
    border-bottom: 1px solid #760fb2;
    padding-left: 16px;
    .tabs-container {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      .tab {
        border: none;
        background-color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 150px;
        flex: 1;
        padding: 10px 20px;
        color: #333;
        font-weight: bold;
        cursor: pointer;
        &.active-tab {
          color: #fff !important;
          background-color: #760fb2 !important;
        }
        &:hover {
          background-color: #f0e5f6;
          color: #760fb2;
        }
      }
    }
  }

  .content-container {
    display: flex;
    .sidebar {
      min-height: calc(100vh - 240px);
      // height: 100%;
      background-color: #fbf3ff;
      padding-top: 40px;
      .menu-container {
        width: 250px;
        list-style: none;
        margin: 0;
        padding: 0;
        .menu-item {
          .menu-item-button {
            border: none;
            background-color: transparent;
            width: 100%;
            flex: 1;
            padding: 10px 20px;
            color: #333;
            font-weight: bold;
            text-align: left;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            &.active-menu {
              color: #fff !important;
              background-color: #760fb2 !important;
            }
            &:hover {
              background-color: #f0e5f6;
              color: #760fb2;
            }
          }
          .submenu-container {
            list-style: none;
            margin: 0;
            padding: 0;
            background-color: #760fb2;
            padding: 8px 16px;
            .submenu-item {
              .submenu-item-button {
                border: none;
                background-color: transparent;
                width: 100%;
                flex: 1;
                padding: 10px 20px;
                color: #f0e5f6;
                font-weight: bold;
                text-align: left;
                cursor: pointer;
                &.active-submenu {
                  color: #760fb2 !important;
                  background-color: #fff !important;
                  border-radius: 5px;
                }
                &:hover {
                  color: #760fb2 !important;
                  background-color: #fff !important;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }

    .main-content {
      padding: 16px;
      overflow-x: hidden;
      // overflow-y: auto;
      flex: 1;
      // height: calc(100vh - 240px);
    }
  }
}
