.react-select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .react-select-label {
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 20px;
    color: #760fb2;
    margin-top: 0;
    padding: 0;
    padding-left: 8px;
    padding-bottom: 4px;
  }
  .css-b62m3t-container {
    width: 100%;
  }

  .react-select {
    width: 100%;
  }

  .helper-text {
    margin-left: 8px;
    font-size: 12px;
    color: #666;

    &.error-text {
      color: #e53935;
    }
  }
}
