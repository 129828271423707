.adherence-graph {
  height: 100%;
  gap: 50px;
  .adherence-container {
    display: flex;
    flex-direction: column;
    // min-width: 440px;
    max-height: 350px;
    padding: 32px;

    .diet-adherence-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 20px;
      width: 50%;

      .diet-adherence-filter {
        display: flex;
        gap: 10px;
      }
      .diet-adherence-heading {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.5px;
      }
    }

    .graph-wrapper {
      display: flex;
      flex-wrap: wrap;
      .graph-container {
        // padding-top: 20px;
        width: 50%;

        .graph-title {
          font-weight: 600;
          font-size: 16px;
          margin-left: 30px;
          text-transform: capitalize;
        }
        .graph {
          //   margin-left: -30px;
          padding-top: 10px;
          width: 400;
          height: 300;
        }
      }
      .graph-container:nth-child(3) {
        width: 100%;
        padding-top: 30px;
      }
    }
  }
  .spinner-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
}
