.score-progress {
    padding: 20px;
  background: #eeeeee;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 15px;
    
        h3 {
          margin: 0;
        }
    
        .chevron {
          transition: transform 0.3s ease;
          
          &.expanded {
            transform: rotate(180deg);
          }
        }
      }
    
      .collapsible-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        
        &.expanded {
          max-height: 1000px; // Adjust this value based on your content height
        }
      }
  
    .progress-cards {
      display: flex;
      align-items: center;
      overflow-x: auto;
      scroll-behavior: smooth;
      padding: 1rem;
      gap: 1rem;
      width: 100%;
      min-width: 0;
      
      -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS
      scrollbar-width: thin; // Firefox
      
      &::-webkit-scrollbar {
        height: 8px; // Horizontal scrollbar height
      }
      
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
        
        &:hover {
          background: #555;
        }
      }

      .pagination-button {
        position: absolute;
        background: white;
        border: 1px solid #e2e8f0;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
        
        &:hover {
          background: #f7fafc;
        }
        
        &.prev {
          left: -16px;
        }
        
        &.next {
          right: -16px;
        }
      }
    }
  
    .progress-card {
      flex: 0 0 auto; // Prevent cards from shrinking
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      width: 250px;
    }
  
    .arrow {
      font-size: 24px;
      color: #666;
    }
  
    .gauge-container {
      text-align: center;
      margin-bottom: 15px;
    }
  
    .gauge {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 15px solid #5c6bc0;
      border-bottom-color: white; 
      margin: 0 auto 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      .score {
        font-size: 32px;
        font-weight: bold;
        color: #333;
      }
  
      .status {
        font-size: 14px;
        color: #5c6bc0;
      }
    }
  
    .range {
      display: flex;
      justify-content: space-between;
      color: #666;
      font-size: 14px;
    }
  
    .details {
      text-align: center;
  
      .name {
        font-weight: 500;
        margin-bottom: 5px;
      }
  
      .date {
        color: #666;
        font-size: 14px;
      }
    }
    
    .loading-indicator {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      color: #718096;
    }
  }


  .user-details-modal {
    padding: 1rem;
  
    h2 {
      color: #2D3748;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
      border-bottom: 2px solid #E2E8F0;
      padding-bottom: 0.75rem;
    }
  
    .user-info {
      display: grid;
      gap: 1rem;
  
      p {
        margin: 0;
        padding: 0.75rem;
        background-color: #F7FAFC;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        
        strong {
          min-width: 100px;
          color: #4A5568;
        }
      }
  
      p:hover {
        background-color: #EDF2F7;
        transition: background-color 0.2s ease;
      }
    }
  }
  
  // Override ant-modal styles for better integration
  .ant-modal-content {
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .ant-modal-header {
    border-bottom: none;
  }

.no-history-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #666;
  font-size: 1rem;
  font-weight: 500;
}