.create-list-modal {
  .ant-modal-content {
    .ant-modal-body {
      height: 560px; // Accounts for modal header (100px) to reach total 660px
      overflow-y: auto;
      overflow-x: hidden; // Prevent horizontal scrolling
      padding: 24px; // Increased padding for better spacing
      
      // Optional: Custom scrollbar styling
      &::-webkit-scrollbar {
        width: 8px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
        
        &:hover {
          background: #555;
        }
      }
    }
  }

  // Style improvements for form elements
  .ant-form-item-label {
    font-weight: 500;
    
    .required {
      color: #ff4d4f;
      margin-left: 4px;
    }
  }

  .adherence-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }

  // Improve slider appearance
  .ant-slider {
    margin: 10px 0;
  }
} 